<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/analyse-roi-campagnes-e-mailing-keyzz-seo__phfau5.jpg')})`,
        }"
      >
        <b-card-body class="text-center">
          <h1 class="text-white">Bienvenue sur le manuel d'utilisation</h1>
          <!-- <h2 class="text-white">
            Puis-je vous aider ?
          </h2> -->
          <b-card-text class="mb-2 text-white">
            <strong> Trouvez rapidement ce que vous cherchez ! </strong>
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="searchQuery"
                placeholder="Rechercher dans le manuel d'utlisation..."
              />
            </b-input-group>
          </b-form>
          <!-- Bouton de création du manuel d'utilisation -->
          <div class="mt-2">
            <b-button
              v-if="$can('create', 'manuel_utilisation')"
              variant="primary"
              @click="$router.push({ name: 'manuel-utilisation-create' })"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Créer un manuel d'utilisation</span>
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </section>

    <section>
      <b-row>
        <b-col md="12">
          <b-card
            v-if="listManuelUtilisations && listManuelUtilisations.length <= 0"
          >
            <h3 class="text-center">Aucun manuel d'utilisation disponible</h3>
          </b-card>
        </b-col>
        <b-col
          v-for="(role, roleIndex) in uniqueRoles"
          :key="roleIndex"
          :md="displayManuelUtilisationRole.admin ? '6' : '12'"
        >
          <b-card v-if="displayManuelUtilisationRole.admin">
            <b-card-header>
              <b-card-title>
                <h2>{{ role }}</h2>
              </b-card-title>
            </b-card-header>
            <hr />
            <b-card-body>
              <ul>
                <li
                  v-for="manuelUtilisation in listManuelUtilisationsByRole(
                    role
                  )"
                  :key="manuelUtilisation.id"
                >
                  <b-link @click="goToDetails(manuelUtilisation.id)">
                    {{ manuelUtilisation.title }}
                  </b-link>
                </li>
              </ul>
            </b-card-body>
          </b-card>
          <b-card v-if="shouldDisplayRole(role)">
            <ul>
              <li
                v-for="manuelUtilisation in listManuelUtilisationsByRole(role)"
                :key="manuelUtilisation.id"
              >
                <b-link @click="goToDetails(manuelUtilisation.id)">
                  {{ manuelUtilisation.title }}
                </b-link>
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BInputGroup,
  BLink,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import localstorageService from "@/services/localstorage/localstorage.service";
import utilsService from "@/services/utils/utils.service";

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import manuelUtilisations from "@/store/manuel-utilisations";

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BCardText,
    BLink,
    BInputGroupPrepend,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      searchQuery: "",
      listManuelUtilisations: null,
      displayManuelUtilisationRole: {
        admin: null,
        agentInterne: null,
        agentExterne: null,
        investisorUEH: null,
        investisorUEAV: null,
        investisorUEGT: null,
        investisorUER: null,
      },
    };
  },

  computed: {
    uniqueRoles() {
      // Retourne une liste de rôles uniques à partir de listManuelUtilisations
      return [
        ...new Set(
          this.listManuelUtilisations &&
            this.listManuelUtilisations.map((manuel) => manuel.roles[0].title)
        ),
      ];
    },
  },

  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      async handler(val, old) {
        await this.loadManuelUtilisations();
        const {
          isAdmin,
          isAgentInterne,
          isAgentExterne,
          isOperatorUEH,
          isOperatorUEAV,
          isOperatorUEGT,
          isOperatorUER,
        } = utilsService.currentUserUtils();
        this.displayManuelUtilisationRole = {
          admin: isAdmin,
          agentInterne: isAgentInterne,
          agentExterne: isAgentExterne,
          investisorUEH: isOperatorUEH,
          investisorUEAV: isOperatorUEAV,
          investisorUEGT: isOperatorUEGT,
          investisorUER: isOperatorUER,
        };
      },
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: "manuel-utilisations", module: manuelUtilisations },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    return {
      requiredStoreModules,
    };
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions("manuel-utilisations", {
      action_fetchManuelUtilisations: "fetchManuelUtilisations",
    }),

    async loadManuelUtilisations() {
      try {
        const response = await this.action_fetchManuelUtilisations();
        if (response.status === 200) {
          this.listManuelUtilisations = response.data.data;
        } else {
          console.log("La réponse n'a pas un status HTTP 200");
        }
      } catch (error) {
        console.log(
          "Erreur lors du chargement de la liste des manuels d'utilisation",
          error
        );
      }
    },

    // listManuelUtilisationsByRole(role) {
    // // Retourne une liste de manuels d'utilisation pour un rôle donné
    //   return this.listManuelUtilisations.filter(manuel => manuel.roles[0].title === role)
    // },

    listManuelUtilisationsByRole(role) {
      // Retourne une liste de manuels d'utilisation pour un rôle donné
      const filteredManuels = this.listManuelUtilisations.filter((manuel) => {
        const titleMatches = manuel.title
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        const roleMatches = manuel.roles[0].title === role;
        return titleMatches && roleMatches;
      });

      return filteredManuels;
    },

    shouldDisplayRole(role) {
      const {
        agentExterne,
        agentInterne,
        investisorUEH,
        investisorUEAV,
        investisorUEGT,
        investisorUER,
      } = this.displayManuelUtilisationRole;

      return (
        (agentExterne && agentExterne.title === role) ||
        (agentInterne && agentInterne.title === role) ||
        (investisorUEH && investisorUEH.title === role) ||
        (investisorUEAV && investisorUEAV.title === role) ||
        (investisorUEGT && investisorUEGT.title === role) ||
        (investisorUER && investisorUER.title === role)
      );
    },

    goToDetails(id) {
      localstorageService.setManuelUtilisationId(id);
      this.$router.push({ name: "manuel-utilisation-details", params: { id } });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
.faq-search {
  background-blend-mode: color;
  background-color: #00000063 !important;
}
</style>
